import React, { Component } from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from "../components/Header"
import Footer from '../components/footer'
import ContactSection from '../components/ContactSection'
import {initMap} from '../utils/utils'
import BlogSection from '../components/BlogSection'
import path2 from "../images/path-02.svg";


const TitleSection = styled.div`
  margin: 160px 0 40px;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;

  @media(max-width: 1184px) {
    align-items: flex-start;
    text-align: left;
    margin: 80px auto 24px;
    width: 95%;
  }
`;

const TagSection = styled.div`
  display: flex;
  align-items: center;
`;

const Tag = styled.h1`
  font-size: 32px;
  line-height: 78px;
  margin-top: 1px;

  @media(max-width: 1184px) {
    line-height: 45px;
  }
`;

const LineLeft = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  margin-right: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const LineRight = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  margin-left: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

class FachbeitragePage extends Component {
  componentDidMount() {
    window.google && initMap();
  }

  render() {
    return (
      <>
        <Header />
        <Layout
          background="linear-gradient(180deg, #0e1c5d, #203bbc)"
          images={[
            {
              path: path2,
              styles: {top: '150px', left: '25%', opacity: '0.3', width: '70%', height: 'auto'},
            }
          ]}
        >
          <SEO title="Gefeas-wiki" />

          <TitleSection>
            <TagSection>
              <LineLeft />
              <Tag>Aktuelles</Tag>
              <LineRight />
            </TagSection>
            <p>Hier finden Sie Fachpublikationen und Medienberichte aus den Themenfeldern der Spezialisten im Aorten- und GefässZentrum. Für Fragen und über die Inhalte hinausgehende Informationsbedürfnisse stehen Ihnen die Autoren gerne zur Verfügung.</p>
          </TitleSection>

          <BlogSection />
          
          <ContactSection title="Aorten- und GefässZentrum" />
        </Layout>
        <Footer />
      </>
    )
  }
}

export default FachbeitragePage
